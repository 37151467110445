import { getReginById } from "@/utils/api";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { BmMarker, BmLabel } from "vue-baidu-map";
// import cancelreasonModal from "./../components/cancelReason.vue";
import { distributorWarehouse, warehouseDetail } from "./api";
import BMap from '../components/BMap/index'
import Util from "@/utils/utils";

import {
  apply,
  getCity,
  getArea,
  getMarketCenter,
  getStreet,
  save,
  dimission,
  getParams,
  getMerchant,
  getMerchantDetail,
  getBsc,
  getPrincipal,
  getPrincipalDetail,
  getSupplier,
  getWlz,
  getStore,
  getSalesman,
  getMarketingCenter,
  getOperator,
  getDetail,
} from "./api copy";
import { publicPath } from "@/common/constant.js";
import { mixin } from "./index-mixin";
import { setStoreUser,setStoreUser1, getStoreUser1 } from '@/utils/store'
import { set } from "nprogress";
let isBooth = null;
export default {
  name: "index",
  mixins: [mixin],
  components: {
    BMap,
    BaiduMap,
    // cancelreasonModal,
    BmMarker,
    BmLabel,
  },
  data() {
    return {
      visible2: false,
      msgTips: "",
      selctRow: "",
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      type: "add", // add edit
      customer: "",
      visible: false,
      bscManagerId: "", // 办事处经理id
      content: "", // 申请原因
      mapShow: false,
      center: "",
      gdCenter: "",
      zoom: 15,
      BMap: null,
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/basicInfo?type=8",
          name: "basicInfo?type=8",
          title: "分销的仓库",
        },
        {
          path: "/distributorWarehouse/addDistributorWarehouse",
          name: "distributorWarehouse",
          title: "新增仓库",
        },
      ],

      spinning: false, //页面整体loading
      editCount: 0, //表单编辑次数 >1则有编辑
      menuList: [
        {
          title: "基础信息",
          active: true,
        },
        {
          title: "物料组合作关系",
          active: false,
        },
      ],
      isFixed: false, //左侧是否跟随
      formData: { ownedBusinessText: '1' },
      //表单验证
      rules: {
        ownedBusiness: [
          {
            required: true,
            message: "请选择所属客户",
            trigger: ["change", "blur"],
          },
        ],
        marketLevel: [
          {
            required: true,
            message: "请选择市场级别",
            trigger: ["change", "blur"],
          },
        ],
        storeFullName: [
          {
            required: true,
            message: "请输入仓库全称",
            trigger: ["change", "blur"],
          },
        ],
        storeShortName: [
          {
            required: true,
            message: "请输入仓库简称",
            trigger: ["change", "blur"],
          },
        ],
        province: [
          {
            required: true,
            message: "请选择省",
            trigger: ["change", "blur"],
          },
        ],
        city: [
          {
            required: true,
            message: "请选择市",
            trigger: ["change", "blur"],
          },
        ],
        area: [
          {
            required: true,
            message: "请选择区县",
            trigger: ["change", "blur"],
          },
        ],
        street: [
          {
            required: true,
            message: "请选择乡镇街道",
            trigger: ["change", "blur"],
          },
        ],
        // marketLv: [{
        //     required: true,
        //     message: "请选择市场级别",
        //     trigger: ["change", "blur"],
        // }],
        isTobeHxStore: [
          {
            required: true,
            message: "请选择是否成为海信专卖店",
            trigger: ["change", "blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "请选择门店地址",
            trigger: ["change", "blur"],
          },
        ],
        postCode: [
          {
            required: true,
            message: "请输入邮政编码",
            trigger: ["change", "blur"],
          },
          {
            validator: this.isPostCode,
            message: "请输入正确的邮政编码",
            trigger: ["change", "blur"],
          },
        ],
        cityLv: [
          {
            required: true,
            message: "请选择城市级别",
            trigger: ["change", "blur"],
          },
        ],
        storefront: [
          {
            required: true,
            message: "请选择店面性质",
            trigger: ["change", "blur"],
          },
        ],
        isSink: [
          {
            required: true,
            message: "请选择是否下沉渠道",
            trigger: ["change", "blur"],
          },
        ],
        storeCategory: [
          {
            required: true,
            message: "请选择门店类别",
            trigger: ["change", "blur"],
          },
        ],
        isCore: [
          {
            required: true,
            message: "请选择是否核心门店",
            trigger: ["change", "blur"],
          },
        ],
        principal: [
          {
            required: true,
            message: "请选择片区负责人",
            trigger: ["change", "blur"],
          },
        ],
        isBooth: [
          {
            required: true,
            message: "请选择是否做展台",
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请输入申请原因",
            trigger: ["change", "blur"],
          },
        ],
        projectRequirements: [
          {
            required: false,
            message: "请输入项目需求名称",
            trigger: ["change", "blur"],
          },
        ],
        circleType: [
          {
            required: true,
            message: "请选择商圈类型",
            trigger: ["change", "blur"],
          },
        ], //商圈类型
        store3Size: [
          {
            required: true,
            message: "请选择门店三大品类年规模",
            trigger: ["change", "blur"],
          },
        ], //门店三大品类年规模
        brandSettled: [
          {
            required: true,
            message: "请选择友商品牌进驻",
            trigger: ["change", "blur"],
          },
        ], //友商品牌进驻
      },
      scrollWatch: true, //滚动flag
      info: "",
      storeDemandIdx: [],
      storeDemand: [
        {
          title: "需求子单号",
          dataIndex: "orderNo",
          width: 150,
        },
        {
          title: "物料组",
          dataIndex: "wlz",
          width: 150,
          scopedSlots: { customRender: "storeDemand" },
        },
        {
          title: "分销网络规模",
          dataIndex: "netSize",
          width: 150,
          scopedSlots: { customRender: "netSize" },
        },
        {
          title: "所属办事处",
          dataIndex: "bsc",
          width: 120,
        },
        {
          title: "需求类型",
          dataIndex: "demandType",
          width: 120,
          scopedSlots: { customRender: "demandType" },
        },
        {
          title: "派单类型",
          dataIndex: "dispatchType",
          width: 120,
          scopedSlots: { customRender: "dispatchType" },
        },
        {
          title: "供应商",
          dataIndex: "supplier",
          width: 220,
          scopedSlots: { customRender: "supplier" },
        },
        {
          title: "费用预估(元)",
          dataIndex: "costEstimate",
          width: 120,
          scopedSlots: { customRender: "costEstimate" },
        },
        {
          title: "要求完成时间",
          dataIndex: "time",
          width: 120,
          scopedSlots: { customRender: "time" },
        },
        {
          title: "说明",
          dataIndex: "illustrate",
          width: 120,
          scopedSlots: { customRender: "illustrate" },
        },
        {
          title: "展台使用年限(年)",
          dataIndex: "usePeriod",
          width: 240,
          scopedSlots: { customRender: "usePeriod" },
        },
      ], // 门店需求
      storeDemands: [], // 门店需求
      selectedRowKeys: [],
      selectedRowKeyscypl: [],
      bscIdx: [], // 办事处下标
      gobacknext: false,//点击上一步显示弹窗
      gobacknext1:false,//单独建店点击上一步显示弹窗
      bsc: [
        {
          title: "所属办事处",
          dataIndex: "bsc",
          width: 200,
          scopedSlots: { customRender: "bsc" },
        },
        {
          title: "分公司名称",
          dataIndex: "company",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "品牌",
          dataIndex: "category",
          width: 200,
          scopedSlots: { customRender: "category" },
        },
        {
          title: "物料组",
          dataIndex: "wlz",
          width: 200,
          scopedSlots: { customRender: "wlz" },
        },
        {
          title: "是否主销楼层",
          dataIndex: "isMainFloor",
          width: 200,
          scopedSlots: { customRender: "isMainFloor" },
        },
        {
          title: "位置排名",
          dataIndex: "locationRanking",
          width: 200,
          scopedSlots: { customRender: "locationRanking" },
        },
        {
          title: "紧临品牌",
          dataIndex: "closedBrand",
          width: 200,
          scopedSlots: { customRender: "closedBrand" },
        },
        {
          title: "计划配置促销员人数",
          dataIndex: "peopleNum",
          width: 200,
          scopedSlots: { customRender: "peopleNum" },
        },
        {
          title: "促销员编码/姓名",
          dataIndex: "promoter",
          width: 200,
          scopedSlots: { customRender: "promoter" },
        },

        {
          title: "门店级别",
          dataIndex: "storeLevel",
          width: 200,
          scopedSlots: { customRender: "storeLevel" },
        },
        {
          title: "所属运营商",
          dataIndex: "operator",
          width: 200,
          scopedSlots: { customRender: "operator" },
        },
        // {
        //     title: "是否品牌园",
        //     dataIndex: "brandPark",
        //     width: 200,
        //     scopedSlots: { customRender: "" }
        // },
        {
          title: "所在楼层",
          dataIndex: "floor",
          width: 200,
          scopedSlots: { customRender: "floor" },
        },
        {
          title: "门店展厅面积",
          dataIndex: "area",
          width: 200,
          scopedSlots: { customRender: "area" },
        },
        {
          title: "展台延米(m)",
          dataIndex: "ztym",
          width: 200,
          scopedSlots: { customRender: "ztym" },
        },
        {
          title: "样机位数量",
          dataIndex: "num",
          width: 200,
          scopedSlots: { customRender: "num" },
        },
        {
          title: "门店经理姓名",
          dataIndex: "manager",
          width: 200,
          scopedSlots: { customRender: "manager" },
        },
        {
          title: "门店经理电话",
          dataIndex: "managerPhone",
          width: 200,
          scopedSlots: { customRender: "managerPhone" },
        },
        {
          title: "营销员姓名/编码",
          dataIndex: "salesman",
          width: 200,
          scopedSlots: { customRender: "salesman" },
        },
        {
          title: "营销员电话",
          dataIndex: "salesmanPhone",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "进驻时间",
          dataIndex: "settlingTime",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "冻结时间",
          dataIndex: "freezeTime",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "通信品牌信联",
          dataIndex: "brand",
          width: 200,
          scopedSlots: { customRender: "brand" },
        },
        {
          title: "是否发送SAP",
          dataIndex: "isSap",
          width: 200,
          scopedSlots: { customRender: "isSap" },
        },
        {
          title: "是否进驻",
          dataIndex: "isEnter",
          width: 200,
          scopedSlots: { customRender: "isEnter" },
        },
        {
          title: "是否TOP客户加盟店",
          dataIndex: "isTop",
          width: 200,
          scopedSlots: { customRender: "isTop" },
        },
        // {
        //     title: "发货分公司",
        //     dataIndex: "shippingCompany",
        //     width: 200,
        //     scopedSlots: { customRender: "shippingCompany" }
        // },
        {
          title: "补货商家",
          dataIndex: "merchant",
          width: 200,
          // scopedSlots: { customRender: "merchant" }
        },
        {
          title: "合作状态",
          dataIndex: "cooperationStatus",
          width: 200,
          scopedSlots: { customRender: "cooperationStatus" },
        },
        {
          title: "冻结凭证",
          dataIndex: "freezeVouchers",
          width: 200,
          scopedSlots: { customRender: "freezeVouchers" },
        },
        {
          title: "营销中心办事处",
          dataIndex: "marketingCenter",
          width: 200,
          scopedSlots: { customRender: "marketingCenter" },
        },
      ], // 办事处
      bscList: [],
      bscInfos: [], //办事处信息
      xsgmIdx: [],
      xsgm: [
        {
          title: "物料组",
          dataIndex: "wlz",
          width: 200,
          scopedSlots: { customRender: "wlz" },
        },
        {
          title: "年销售规模（万元）",
          dataIndex: "nxsgm",
          // width: 400,
          scopedSlots: { customRender: "nxsgm" },
        },
        {
          title: "预计门店规模（万元）",
          dataIndex: "yjmdgm",
          // width: 400,
          scopedSlots: { customRender: "yjmdgm" },
        },
      ], //销售规模
      xsgmList: [],
      MarketTableList: [],
      tableData: [], // 物料组数据
      lctableData: [], // 临促数据
      TselectedRowKeys: [],
      lcselectedRowKeys: [],
      ownedBusiness: [], //所属商家列表
      marketLevels: [], //市场级别
      storeFullNames: [], //门店全称
      storeShortNames: [], //门店简称
      province: [], //省
      provinceStr: "", //
      city: [], //市
      cityStr: "",
      area: [], //区
      areaStr: "",
      street: [], //街道
      streetStr: "",
      // marketLv: [],//市场级别
      cityLv: [], //城市级别
      storefront: [], //店面性质
      storeCategory: [], //门店类别
      principal: [], //片区负责人
      isCore: [], //是否核心店铺
      isEnter: [], //是否入住
      djFlag: [], //冻结
      isSink: [], //是否下沉
      isBooth: [], //是否做展台
      isTop: [], //是否top客户加盟店
      storeLevel: [], //门店级别
      merchantDetail: "", //商家详情
      marketingCenter: "", //营销中心
      brand: [], //通信品牌信联
      isSap: [], //是否发送sap
      demandType: [], //需求类型
      dispatchType: [], //派单类型
      wlz: [], //
      salesman: [], //营销员
      marketingCenterBsc: [],
      operator: [], //所属运营商
      supplier: [], //供应商
      category: [],
      cooperationStatus: [], //合作状态
      bscWlz: [], //办事处物料组 code name bsc

      circleType: [], //商圈类型
      store3Size: [], //门店三大品类年规模
      brandSettled: [], //友商品牌进驻
      isMainFloor: [], //是否主销楼层
      closedBrand: [], // 紧临品牌
      promoter: [], //促销员
      isStandard: [], //是否达标
      isedit:false//是否编辑状态
    };
  },
  watch: {},
  destroyed() {
    this.scrollWatch = false;
  },
  mounted() {
    // this.setDistributorWarehouse()
    // getReginById()

    $(window).on("scroll", this.handleScroll);
    this.customer = this.$store.state.user.userInfo.customer;
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.type == "add") {
      this.formData = {
        znxsgm: 0, //总年销售规模(万元)：
        zyjmdgm: 0, // 总预计门店规模(万元)：
        isBooth: "", //是否做展台
        cis: "",
        mdm: "",
        ownedBusiness: "", //所属商家
        storeFullName: "", //门店全称
        storeShortName: "", //门店简称
        marketLevel: "", //市场级别
        province: "", //省
        city: "",
        area: "",
        street: "",
        // marketLv: '', //市场级别
        address: "",
        addrDescribe: "", //地址描述
        marketingCenter: "", //营销中心
        marketingCenterId: "",
        postCode: "", //邮编
        cityLv: "", //城市级别
        storefront: "", //店面性质
        isSink: "0", //是否下沉渠道
        storeCategory: [], //门店类别
        from: "信天翁", //来源
        isTobeHxStore: "", // 是否计划成为海信专卖店
        channelCategory: "", //渠道类别
        channelCategoryId: "",
        channelCategorySub: "", //渠道类别细分
        channelCategorySubId: "",
        labelLv1: "", //集团一级标签
        labelLv1Id: "",
        labelLv2: "", //集团二级标签
        labelLv2Id: "",
        isCore: "", //是否核心门店
        principal: "", // 片区负责人
        principalPhone: "", //片区负责人电话
        headquartersMarketCategory: "", //总部市场类别
        projectRequirements: "", //项目需求名称：
        planProject: "展台制作费-非专卖店", //计划项目名称：
        content: "", //申请原因
        circleType: "", //商圈类型
        store3Size: "", //门店三大品类年规模
        brandSettled: "", //友商品牌进驻
        isStandard: "", //是否达标
        standardDescribe: "", //达标描述
      };
      apply().then((res) => {
        this.province = res.data.provicesList;
      });
      this.getParams().then(res => {
        // this.formData.marketLevel = '17250'
      });
    }
    if (this.type == "edit") {
      this.isedit=true
      apply().then((res) => {
        this.province = res.data.provicesList;
        // this.getDetail();
      });
    }

    if (["check", "edit"].indexOf(this.type) != -1) {
      if (this.type == "check") {
        this.disableFlag = true;
      }
      let temp = JSON.parse(sessionStorage.getItem("selectedRow"));
      temp = temp[0];
      this.getParams().then(res=>{
        this.$nextTick(()=> {
          setTimeout(()=> {
            this.getwarehouseDetail(temp)

          },1000)

        })

      });


      
    }
    // debugger
  },
  methods: {
    getwarehouseDetail(temp) {
      warehouseDetail(temp.id).then((res) => {
        let fullInfo = res.data.data;
        this.formData.ownedBusinessText =
          fullInfo.custInfoId_name || temp.fullName;
        this.formData.storeFullName =
          fullInfo.fullName || temp.custInfoFullName;
        this.formData.storeShortName =
          fullInfo.searchTerm || temp.custInfoSearchTerm;
        this.formData.postCode = fullInfo.postalCode || temp.custInfoSearchTerm;
        // this.formData.marketLevel =
        // fullInfo.marketLevelText||fullInfo.marketLevelId || temp.custInfoSearchTerm;
        // debugger
        // this.formData.marketLevel ='17250'
        this.formData.marketLevel = fullInfo.marketLevelId + ''
        this.marketLeveldefaultValue = fullInfo.marketLevelId
        this.formData.ownedBusiness =
          fullInfo.shAddress || temp.custInfoSearchTerm;
        this.formData.ownedBusiness = fullInfo.custInfoId || temp.fullName;
        this.formData.mapAddress = fullInfo.shAddress||''
        this.goGetMatklList()
        this.addressArr = [
          String(fullInfo.provinceId),
          String(fullInfo.cityId),
          String(fullInfo.countyId),
          String(fullInfo.townId),
        ];
      
        this.addressHx = [
          String(fullInfo.provinceId),
          String(fullInfo.cityId),
          String(fullInfo.countyId),
          String(fullInfo.townId),
        ];
        if (fullInfo.shopInfoCheckDetailList.length > 0) {
          fullInfo.shopInfoCheckDetailList.forEach(i => {
            this.shopInfoCheckDetailList.push({
              fullName: i.baseMatklName + "-" + i.fgsName,
              orgName: i.orgName, //办事处
              managerName: i.managerName, //仓库联系人姓名
              managerTel: i.managerTel, //仓库联系人电话
              enterTime: i.enterTime, //进驻时间
              orgId: i.orgId,//办事处
              fgsName: i.fgsName,//分公司
              fgsId: i.fgsId,//分公司
              baseMatklId: i.baseMatklId,//物料组
              baseMatklName: i.baseMatklName,//物料组
              id:i.id
            });
          });
        }
        this.formData = { ...this.formData };
      });
    },
    getDetail() {
      getDetail(this.$route.query.id).then((res) => {
        let info = res.data.data;
        let znxsgm = 0;
        let zyjmdgm = 0;
        let xsgmList = [];
        if (info.custShopInfoSalesCheckList) {
          for (let item of info.custShopInfoSalesCheckList) {
            // znxsgm += Number(item.annualSales);
            znxsgm = this.accAdd(znxsgm, item.annualSales);
            // zyjmdgm += Number(item.forcastSales)
            zyjmdgm = this.accAdd(zyjmdgm, item.forcastSales);
            xsgmList.push({
              wlzName: item.matklName,
              id: item.id,
              isNewRow: false,
              wlz: item.matklId + "",
              nxsgm: item.annualSales,
              yjmdgm: item.forcastSales,
            });
          }
          this.xsgmList = xsgmList;
        }
        let storeDemands = [];
        if (info.supplyDetailList) {
          for (let item of info.supplyDetailList) {
            storeDemands.push({
              orderNo: item.supplyDetailCode,
              id: item.id,
              isNewRow: false,
              wlz: item.baseMatklId, //物料组
              wlzName: item.baseMatklName,
              bsc: item.custShopInfoDetailCheckDto.orgName, //所属办事处
              demandType: item.supplyTypeId + "", //需求类型
              dispatchType: item.isHeaderUnion + "", //派单类型
              supplier: item.supplierCode, //供应商
              supplierName: item.supplierName,
              costEstimate: item.estimatedCost, //费用预估
              time: item.finishDate, //要求完成时间
              illustrate: item.remark, //说明
            });
          }
          this.storeDemands = storeDemands;
        }
        if (info.isUpStandard == 1) {
          info.isUpStandard = "是";
        }
        if (info.isUpStandard == 0) {
          info.isUpStandard = "否";
        }
        this.formData = {
          znxsgm, //总年销售规模(万元)：
          zyjmdgm, // 总预计门店规模(万元)：
          isBooth: info.isSubmitSupplyId + "", //是否做展台
          isBoothText: info.isSubmitSupplyText, //是否做展台
          cis: info.cisCode,
          mdm: info.mdmCode,
          ownedBusiness: info.custInfoId, //所属商家
          ownedBusinessText: info.custInfoId_name, //所属商家
          storeFullName: info.fullName, //门店全称
          storeShortName: info.searchTerm, //门店简称
          marketLevel: info.marketLevelId + "", //市场级别
          address: info.mapAddress,
          addrDescribe: info.addressDesc, //地址描述
          marketingCenter: info.marketCenterText, //营销中心
          marketingCenterId: info.marketCenterId,
          postCode: info.postalCode, //邮编
          cityLv: info.districtType, //城市级别
          storefront: info.shopPropertyId + "", //店面性质
          isSink: info.isSinkChannel, //是否下沉渠道
          storeCategory: info.shopCategory, //门店类别
          from: "信天翁", //来源
          isTobeHxStore:
            info.isPlanSpecial != null ? info.isPlanSpecial + "" : "",
          channelCategory: "", //渠道类别
          channelCategoryId: "",
          channelCategorySub: "", //渠道类别细分
          channelCategorySubId: "",
          labelLv1: "", //集团一级标签
          labelLv1Id: "",
          labelLv2: "", //集团二级标签
          labelLv2Id: "",
          isCore: info.isCoreShop, //是否核心门店 == 1?'是' :'否'
          principalPhone: info.areaLeaderId, //片区负责人电话
          headquartersMarketCategory: "", //总部市场类别
          projectRequirements: info.projectSupplyName, //项目需求名称：
          planProject: "展台制作费-非专卖店", //计划项目名称：
          content: info.content, // 申请原因

          circleType: info.tradingTypeId + "", //商圈类型
          store3Size: info.threeScaleId + "", //门店三大品类年规模
          brandSettled: info.friendBrandId[0] ? info.friendBrandId[0] : "", //友商品牌进驻
          isStandard: info.isUpStandard, //是否达标
          standardDescribe: info.noStandardReason, //达标描述
        };
        if (info.isSubmitSupplyId == 14923253081) {
          this.rules = {
            ownedBusiness: [
              {
                required: true,
                message: "请选择所属商家",
                trigger: ["change", "blur"],
              },
            ],
            marketLevel: [
              {
                required: true,
                message: "请选择市场级别",
                trigger: ["change", "blur"],
              },
            ],
            storeFullName: [
              {
                required: true,
                message: "请输入仓库全称",
                trigger: ["change", "blur"],
              },
            ],
            storeShortName: [
              {
                required: true,
                message: "请输入仓库简称",
                trigger: ["change", "blur"],
              },
            ],
            province: [
              {
                required: true,
                message: "请选择省",
                trigger: ["change", "blur"],
              },
            ],
            city: [
              {
                required: true,
                message: "请选择市",
                trigger: ["change", "blur"],
              },
            ],
            area: [
              {
                required: true,
                message: "请选择区县",
                trigger: ["change", "blur"],
              },
            ],
            street: [
              {
                required: true,
                message: "请选择乡镇街道",
                trigger: ["change", "blur"],
              },
            ],
            // marketLv: [{
            //     required: true,
            //     message: "请选择市场级别",
            //     trigger: ["change", "blur"],
            // }],
            address: [
              {
                required: true,
                message: "请选择门店地址",
                trigger: ["change", "blur"],
              },
            ],
            isTobeHxStore: [
              {
                required: true,
                message: "请选择是否成为海信专卖店",
                trigger: ["change", "blur"],
              },
            ],
            postCode: [
              {
                required: true,
                message: "请输入邮政编码",
                trigger: ["change", "blur"],
              },
              {
                validator: this.isPostCode,
                message: "请输入正确的邮政编码",
                trigger: ["change", "blur"],
              },
            ],
            cityLv: [
              {
                required: true,
                message: "请选择城市级别",
                trigger: ["change", "blur"],
              },
            ],
            storefront: [
              {
                required: true,
                message: "请选择店面性质",
                trigger: ["change", "blur"],
              },
            ],
            isSink: [
              {
                required: true,
                message: "请选择是否下沉渠道",
                trigger: ["change", "blur"],
              },
            ],
            storeCategory: [
              {
                required: true,
                message: "请选择门店类别",
                trigger: ["change", "blur"],
              },
            ],
            isCore: [
              {
                required: true,
                message: "请选择是否核心门店",
                trigger: ["change", "blur"],
              },
            ],
            principal: [
              {
                required: true,
                message: "请选择片区负责人",
                trigger: ["change", "blur"],
              },
            ],
            isBooth: [
              {
                required: true,
                message: "请选择是否做展台",
                trigger: ["change", "blur"],
              },
            ],
            content: [
              {
                required: true,
                message: "请输入申请原因",
                trigger: ["change", "blur"],
              },
            ],
            projectRequirements: [
              {
                required: true,
                message: "请输入项目需求名称",
                trigger: ["change", "blur"],
              },
            ],
            circleType: [
              {
                required: true,
                message: "请选择商圈类型",
                trigger: ["change", "blur"],
              },
            ], //商圈类型
            store3Size: [
              {
                required: true,
                message: "请选择门店三大品类年规模",
                trigger: ["change", "blur"],
              },
            ], //门店三大品类年规模
            brandSettled: [
              {
                required: true,
                message: "请选择友商品牌进驻",
                trigger: ["change", "blur"],
              },
            ], //友商品牌进驻
          };
        }
        getMerchantDetail(info.custInfoId).then((res) => {
          let merchantDetail = res.data.data;
          this.formData.channelCategory = merchantDetail.primaryChannelText;
          this.formData.channelCategoryId = merchantDetail.primaryChannelId;

          this.formData.channelCategorySub = merchantDetail.secondChannelText;
          this.formData.channelCategorySubId = merchantDetail.secondChannelId;

          this.formData.labelLv1 = merchantDetail.primaryLabelText;
          this.formData.labelLv1Id = merchantDetail.primaryLabelId;

          this.formData.labelLv2 = merchantDetail.secondLabelText;
          this.formData.labelLv2Id = merchantDetail.secondLabelId;
        });
        getBsc(this.formData.ownedBusiness).then((res) => {
          this.bscInfos = res.data.list;
          let bscList = [];
          let bscWlz = [];
          for (let item of info.shopInfoDetailCheckList) {
            bscWlz.push({
              bsc: item.orgId,
              bscName: item.orgName,
              code: item.baseMatklId + "",
              name: item.baseMatklName,
            });
            let data = {
              id: item.id,
              isNewRow: false,
              bsc: item.orgId, //所属办事处
              bscId: item.orgId, //所属办事处
              bscName: item.orgName || "", //所属办事处
              company: item.fgsName || "", //分公司名称
              companyId: item.orgId, //分公司名称
              category: item.brandId && item.brandId + "", //品类
              wlz: item.baseMatklId + "", //物料组
              wlzName: item.baseMatklName || "",
              storeLevel: item.shopLevelId + "", //门店级别
              operator: item.runCustTypeName || "", //所属运营商
              operatorId: item.runCustTypeId || "", //所属运营商
              brandPark: "", //是否品牌园
              floor: item.shopFloor || "", // 所在楼层
              area: item.shopArea || "", //门店展厅面积
              ztym: item.singleMeter, //展台延米
              num: item.modelCount || "", //样机位数量
              manager: item.managerName || "", //门店经理姓名
              managerPhone: item.managerTel || "", //门店经理电话
              salesman: item.sellerId, //营销员姓名/编码
              salesmanText: item.sellerName || "",
              salesmanPhone: item.sellerTel || "", //营销员电话
              settlingTime: item.enterTime || "", //进驻时间
              freezeTime: item.djTime || "", //冻结时间
              brand: item.netStandardId && item.netStandardId + "", //通信品牌信联
              isSap: item.isToSap, //是否发送SAP
              isEnter: item.isEnter,
              isTop: item.isTopShop,
              // shippingCompany: '',//发货分公司
              merchant: item.warehouseName || "", //补货商家
              merchantId: item.warehouseId || "", //补货商家
              cooperationStatus: item.djFlag, //合作状态
              freezeVouchers: item.djVouchers, //冻结凭证
              marketingCenter: item.terminalOrgId && item.terminalOrgId + "", //营销中心办事处
              file: [],
              isMainFloor: item.isMainFloor, //是否主销楼层
              locationRanking: item.placeRank, //位置排名
              closedBrand: item.closedBrandId + "", // 紧临品牌
              peopleNum: item.cxPeopleCount, //计划配置促销员人数
              promoter: item.cxPeopleId, //促销员
              promoterName: item.cxPeopleName,
            };
            if (item.djVouchers[0]) {
              data.file = [
                {
                  name: item.djVouchers[0].attachShortName,
                  id: item.djVouchers[0].id,
                },
              ];
            }
            bscList.push(data);
          }
          this.bscList = bscList;
          this.bscWlz = bscWlz;
        });

        for (let i = 0; i < info.shopInfoDetailCheckList.length; i++) {
          let item = info.shopInfoDetailCheckList[i];
          getWlz(this.customer.id, item.orgId).then((res) => {
            this.bscList[i].wlzList = res.data.list;
            this.bscList[i].wlz =
              info.shopInfoDetailCheckList[i].baseMatklId + "";
            this.$forceUpdate();
          });
        }
        if (info.shopInfoDetailCheckList[0]) {
          // getWlz(this.customer.id, info.shopInfoDetailCheckList[0].orgId).then(res => {
          //     this.wlz = res.data.list
          // })
          getMarketingCenter(info.shopInfoDetailCheckList[0].orgId).then(
            (res) => {
              this.marketingCenterBsc = res.data.list;
            }
          );
          // data = {
          //     searchstr: '',
          //     officeId: info.shopInfoDetailCheckList[0].orgId
          // }
          // getSalesman(data).then(res => {
          //     this.salesman = res.data.list
          // })
        }

        this.getParams();
        let data = {
          proviceId: info.provinceId,
        };
        getCity(data).then((res) => {
          this.city = res.data;
        });
        data = {
          cityId: info.cityId,
        };
        getArea(data).then((res) => {
          this.area = res.data;
        });
        data = {
          districtId: info.countyId,
        };
        getStreet(data).then((res) => {
          this.street = res.data;
        });
        this.formData.province = info.provinceId;
        this.formData.city = info.cityId;
        this.formData.area = info.countyId;
        this.formData.street = info.townId;
        this.provinceStr = info.provinceName;
        this.cityStr = info.cityName;
        this.areaStr = info.countyName;
        this.streetStr = info.townName;
        data = {
          searchstr: "",
        };
        getPrincipal(data).then((res) => {
          if (res.data.code == 0) {
            this.principal = res.data.list;
            this.formData.principal = info.areaLeaderId;
            this.formData.principalName = info.areaLeaderId_name;
          }
        });
      });
    },
    checkMap() {
      if (!this.provinceStr) {
        this.$message.info("请选择省份", 2);
        return;
      }
      if (!this.cityStr) {
        this.$message.info("请选择城市", 2);
        return;
      }
      if (!this.areaStr) {
        this.$message.info("请选择区县", 2);
        return;
      }
      if (!this.streetStr) {
        this.$message.info("请选择乡镇街道", 2);
        return;
      }
      this.center =
        this.provinceStr + this.cityStr + this.areaStr + this.streetStr;
      this.mapShow = true;
    },
    mapReady() {
      this.zoom = 18;
      this.BMap = BMap;
    },
    mapTap(e) {
      this.center = {
        lng: e.Bg.lng,
        lat: e.Bg.lat,
      };
      const myGeo = new this.BMap.Geocoder();
      myGeo.getLocation(
        new this.BMap.Point(this.center.lng, this.center.lat),
        (result) => {
          if (result) {
            // address: "山东省青岛市市南区镇江路101"
            this.formData.address = result.address;
            this.gdCenter = this.bgps_gps(this.center.lng, this.center.lat);
          }
        }
      );
    },

    //百度坐标转高德（传入经度、纬度）
    bgps_gps(bd_lng, bd_lat) {
      var X_PI = (Math.PI * 3000.0) / 180.0;
      var x = bd_lng - 0.0065;
      var y = bd_lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      var gg_lng = z * Math.cos(theta);
      var gg_lat = z * Math.sin(theta);
      return { lng: gg_lng.toFixed(6), lat: gg_lat.toFixed(6) };
    },
    isPostCode(e, v) {
      return /^[0-9]{6}$/.test(Number(v));
    },
    //左侧导航点击
    navClickFun(index, isScroll) {
      for (const idx in this.menuList) {
        // debugger
        if (idx == index) {
          if (!isScroll) {
            $("body,html").animate(
              {
                scrollTop: $("#type" + index).offset().top,
              },
              0
            );
          }
          //活动状态
          let timer = setTimeout(() => {
            for (const idx in this.menuList) {
              this.menuList[idx].active = false;
            }
            this.menuList[index].active = true;
            clearTimeout(timer);
          }, 1);
          return;
        }
      }
    },

    //左侧导航跟随滚动
    navScrollFun(index, isScroll) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      for (const idx in this.menuList) {
        if (idx == index) {
          this.menuList[index].active = true;
          return;
        }
      }
    },
    //判断详情通栏是否吸附
    handleScroll() {
      if (!this.scrollWatch) {
        return;
      }
      if ($("#boxFixedRefer").offset() && $("#boxFixedRefer").offset().top) {
        let scrollTop =
          $("#boxFixedRefer").offset().top - $(window).scrollTop();
        if (scrollTop <= 0) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }
      //滚动-》左侧交互
      // if ($("#type2").offset().top - $(window).scrollTop() < 40) {
      //     this.navScrollFun(2, true);
      // }
      if ($("#type1").offset().top - $(window).scrollTop() < 40) {
        // debugger
        this.navScrollFun(1, true);
      } else if ($("#type0").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(0, true);
      }
    },

    //左侧点击交互
    navJumpFun(index) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      this.menuList[index].active = true;
    },

    provinceChange(e, item) {
      this.provinceStr = this.province[item.data.key].provinceName;
      this.formData.city = "";
      this.formData.area = "";
      this.formData.street = "";
      this.city = [];
      this.area = [];
      this.street = [];
      let data = {
        proviceId: e,
      };
      getCity(data).then((res) => {
        this.city = res.data;
      });
    },
    cityChange(e, item) {
      this.cityStr = this.city[item.data.key].cityName;
      this.formData.area = "";
      this.formData.street = "";
      this.area = [];
      this.street = [];
      let data = {
        cityId: e,
      };
      getArea(data).then((res) => {
        this.area = res.data;
      });
    },
    areaChange(e, item) {
      this.areaStr = this.area[item.data.key].districtName;
      this.formData.street = "";
      this.street = [];
      let data = {
        districtId: e,
      };
      getStreet(data).then((res) => {
        this.street = res.data;
      });
      // 根据区县查询营销中心
      getMarketCenter(e).then((res) => {
        if (res.data.code == 0 && res.data.list[0]) {
          this.marketingCenter = res.data.list[0];
          this.formData.marketingCenter = res.data.list[0].name;
          this.formData.marketingCenterId = res.data.list[0].code;
        }
      });
    },
    streetChange(e, item) {
      this.streetStr = this.street[item.data.key].townName;
    },
    gobacktolist() {
      this.gobacknext1=true
    },
    istruegoback1() {
      this.$router.push("/basicInfo?type=8");
      
    },
    // 返回
    goback() {
      // this.$confirm({
      //   title: "本次修改该尚未保存，是否确认离开",
      //   onOk: () => {

      //     if (getStoreUser1() == 4) {
      //       setStoreUser1(2);
      //       this.$router.push("/distributionStores/addDistributionStore");
      //     } else if (getStoreUser1() == 3) {
      //       setStoreUser1(1);
      //       this.$router.push("/newMerchantaddEdit");
      //     }
      //   },
      //   onCancel() { },
      // });
      this.gobacknext=true

    },
    istruegoback() {
      let jiancangdata = []
      if (this.shopInfoCheckDetailList.length > 0) {
        this.shopInfoCheckDetailList.forEach((i) => {
          jiancangdata.push({
            orgName: i.orgName, //办事处
            fullName:i.fullName,
            fullName1: i.baseMatklName,
            materialGroupBranchCompany: i.baseMatklId,
            orgId: i.orgId,
            fgsName: i.fgsName,//分公司
            fgsId: i.fgsId,//分公司
            managerTel: i.managerTel,
            managerName: i.managerName,
            enterTime: i.enterTime,
          });
        });
      }
      let wareHouseInfoDto = {
        houseFullName: this.formData.storeFullName,
        houseSearchTerm: this.formData.storeShortName,
        houseAddress: this.formData.mapAddress,
        shopInfoDetailCkCheckDtoList: jiancangdata,
      };
      wareHouseInfoDto.shopInfoDetailCkCheckDtoList.map((i) => {
        i.enterTime = Util.dateFmt(new Date(i.enterTime), "yyyy-MM-dd");
      });
      let params = { ...this.sessionData[0], wareHouseInfoDto };
        if (getStoreUser1() == 4) {
            setStoreUser1(2);
            setStoreUser(params)
            this.$router.push({path:"/distributionStores/addDistributionStore",query:{category:'step'}});
          } else if (getStoreUser1() == 3) {
            setStoreUser1(1);
            setStoreUser(params)
            this.$router.push({path:"/newMerchantaddEdit",query:{category:'step'}});
          }
    },

    //是否根据输入项进行筛选
    filterOption(input, option) {
      return;
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 选择门店需求同步提报
    storeDemandSelect(idx) {
      this.storeDemandIdx = idx;
    },
    // 添加门店需求同步提报
    addStoreDemand() {
      this.storeDemands.push({
        isNewRow: true,
        orderNo: "", //需求子单号
        wlz: "", //物料组
        bsc: "", //所属办事处
        demandType: "14182400546", //需求类型 写死新店开业
        dispatchType: "0", //派单类型 默认普通安装派单
        supplier: "", //供应商
        costEstimate: "", //费用预估
        time: "", //要求完成时间
        illustrate: "", //说明
        category: [], //品牌
        startYear: "",
        endYear: "",
        netSize: "", //网络规模
      });
    },
    // 删除门店需求同步提报
    delStoreDemand() {
      if (this.storeDemandIdx.length <= 0) {
        this.$message.warning("请至少选择一项数据!");
        return;
      }
      if (this.storeDemandIdx.length > 1) {
        this.$message.warning("只能一条数据进行删除!");
        return;
      }
      let obj = this.storeDemands[this.storeDemandIdx[0]];
      if (obj.isNewRow) {
        this.storeDemands = this.storeDemands.filter(
          (item, index) => !this.storeDemandIdx.includes(index)
        );
        this.storeDemandIdx = [];
      } else {
        this.$message.warning("已提交保存的数据不能删除");
      }
    },
    // 复制门店需求同步提报
    copyStoreDemand() {
      if (this.storeDemandIdx.length <= 0) {
        this.$message.warning("请至少选择一项数据!");
        return;
      }
      if (this.storeDemandIdx.length > 1) {
        this.$message.warning("只能一条数据进行复制!");
        return;
      }
      let obj = this.storeDemands[this.storeDemandIdx[0]];
      this.storeDemands.push(JSON.parse(JSON.stringify(obj)));
    },
    // 办事处列表选择
    bscSelect(idx) {
      this.bscIdx = idx;
    },
    // 添加办事处
    addBsc() {
      if (!this.formData.ownedBusiness) {
        this.$message.info("请选择所属商家", 2);
        return;
      }
      this.bscList.push({
        isNewRow: true,
        bsc: "", //所属办事处
        bscId: "", //所属办事处
        company: "", //分公司名称
        companyId: "", //分公司名称
        category: "", //品类
        wlz: "", //物料组
        storeLevel: "", //门店级别
        operator: "", //所属运营商
        operatorId: "",
        brandPark: "", //是否品牌园
        floor: "", // 所在楼层
        area: "", //门店展厅面积
        num: "", //样机位数量
        manager: "", //门店经理姓名
        managerPhone: "", //门店经理电话
        salesman: "", //营销员姓名/编码
        salesmanPhone: "", //营销员电话
        settlingTime: "", //进驻时间
        freezeTime: "", //冻结时间
        brand: "", //通信品牌信联
        isSap: "", //是否发送SAP
        isEnter: "",
        isTop: "",
        // shippingCompany: '',//发货分公司
        merchant: this.customer.customerName, //补货商家
        merchantId: this.customer.id, //补货商家
        cooperationStatus: "1", //合作状态
        freezeVouchers: "", //冻结凭证
        marketingCenter: "", //营销中心办事处
        file: [],
        // idx: this.bscList.length
        isMainFloor: "", //是否主销楼层
        locationRanking: "", //位置排名
        closedBrand: "", // 紧临品牌
        peopleNum: 0, //计划配置促销员人数
        promoter: "", //促销员
        promoterName: "",
        ztym: "", //展台延米
      });
    },
    // 删除办事处
    delBsc() {
      if (this.bscIdx.length <= 0) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (this.bscIdx.length > 1) {
        this.$message.warning("只能一条数据进行删除!");
        return;
      }
      let obj = this.bscList[this.bscIdx[0]];
      if (obj.isNewRow) {
        this.bscList = this.bscList.filter(
          (item, index) => !this.bscIdx.includes(index)
        );
        this.bscIdx = [];
      } else {
        this.$message.warning("已提交保存的数据不能删除");
      }
    },
    // 复制办事处
    copyBsc() {
      if (this.bscIdx.length <= 0) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (this.bscIdx.length > 1) {
        this.$message.warning("只能一条数据进行复制!");
        return;
      }
      let obj = this.bscList[this.bscIdx[0]];
      this.bscList.push(JSON.parse(JSON.stringify(obj)));
    },
    // 选择销售规模
    xsgmSelect(idx) {
      this.xsgmIdx = idx;
    },
    // 添加销售规模
    addXsgm() {
      this.xsgmList.push({
        isNewRow: true,
        wlz: "",
        wlzName: "",
        nxsgm: 0,
        yjmdgm: 0,
      });
    },
    // 删除销售规模
    delXsgm() {
      if (this.xsgmIdx.length <= 0) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (this.xsgmIdx.length > 1) {
        this.$message.warning("只能一条数据进行删除!");
        return;
      }
      let obj = this.xsgmList[this.xsgmIdx[0]];
      if (obj.isNewRow) {
        this.xsgmList = this.xsgmList.filter(
          (item, index) => !this.xsgmIdx.includes(index)
        );
        this.xsgmIdx = [];
      } else {
        this.$message.warning("已提交保存的数据不能删除");
      }
    },
    // 复制销售规模
    copyXsgm() {
      if (this.xsgmIdx.length <= 0) {
        this.$message.warning("请至少选择一项数据!");
        return;
      }
      if (this.xsgmIdx.length > 1) {
        this.$message.warning("只能一条数据进行复制!");
        return;
      }
      let obj = this.xsgmList[this.xsgmIdx[0]];
      this.xsgmList.push(JSON.parse(JSON.stringify(obj)));
    },
    // 年销售规模输入
    nxsgmInput(n) {
    },
    getTotal() {
      let znxsgm = 0; //总年销售规模(万元)：
      let zyjmdgm = 0; //总预计门店规模(万元)：
      for (let item of this.xsgmList) {
        // znxsgm += item.nxsgm;
        znxsgm = this.accAdd(znxsgm, item.nxsgm);
        // zyjmdgm += item.yjmdgm;
        zyjmdgm = this.accAdd(zyjmdgm, item.yjmdgm);
      }
      this.formData.znxsgm = znxsgm;
      this.formData.zyjmdgm = zyjmdgm;
    },
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
    // 获取商家列表
    getMerchant(e) {
      let data = {
        searchstr: e,
      };
      getMerchant(data).then((res) => {
        if (res.data.code == 0) {
          this.ownedBusiness = res.data.list;
        }
      });
    },
    // 所属商家改变
    ownedBusinessChange(e) {
      this.$nextTick(() => {
        this.goGetMatklList();

      })
      // debugger
      this.formData.ownedBusiness = this.ownedBusiness[e].code;
      this.formData.ownedBusinessText = this.ownedBusiness[e].name;
      // 获取渠道类别，渠道类别细分，集团一级标签，集团二级标签
      getMerchantDetail(this.formData.ownedBusiness).then((res) => {
        let merchantDetail = res.data.data;
        this.formData.channelCategory = merchantDetail.primaryChannelText;
        this.formData.channelCategoryId = merchantDetail.primaryChannelId;

        this.formData.channelCategorySub = merchantDetail.secondChannelText;
        this.formData.channelCategorySubId = merchantDetail.secondChannelId;

        this.formData.labelLv1 = merchantDetail.primaryLabelText;
        this.formData.labelLv1Id = merchantDetail.primaryLabelId;

        this.formData.labelLv2 = merchantDetail.secondLabelText;
        this.formData.labelLv2Id = merchantDetail.secondLabelId;
      });
      // 获取办事处
      getBsc(this.formData.ownedBusiness).then((res) => {
        this.bscInfos = res.data.list;
      });
      this.wlz = [];
      this.marketingCenterBsc = [];
      for (let item of this.bscList) {
        item.bsc = "";
        item.bscId = "";
        item.companyId = "";
        item.company = "";
        item.wlz = "";
        item.marketingCenter = "";
      }
      for (let item of this.xsgmList) {
        item.wlz = "";
      }
      for (let item of this.storeDemands) {
        item.wlz = "";
      }
      this.formData = { ...this.formData };
      // debugger
    },
    principalInput(e) {
      let data = {
        searchstr: e,
      };
      getPrincipal(data).then((res) => {
        if (res.data.code == 0) {
          this.principal = res.data.list;
        }
      });
    },
    // 片区负责人改变
    principalChange(e) {
      this.formData.principal = this.principal[e].code;
      this.formData.principalName = this.principal[e].name;
      getPrincipalDetail(this.formData.principal).then((res) => {
        if (res.data.code == 0) {
          this.formData.principalPhone = res.data.data.loginName;
        }
      });
    },
    // 门店需求物料组改变
    storeDemandWlzChange(idx, item) {
      // item.wlz = 1111;
      item.bsc = this.bscWlz[idx].bscName;
      item.wlz = this.bscWlz[idx].code;
    },
    // 办事处改变
    bscChange(idx, item) {
      item.bsc = this.bscInfos[idx].officeId;
      item.bscName = this.bscInfos[idx].officeName;
      item.bscId = this.bscInfos[idx].officeId;
      item.companyId = this.bscInfos[idx].officeId;
      item.company = this.bscInfos[idx].orgName;
      item.wlz = "";
      item.wlzName = "";
      getWlz(this.customer.id, this.bscInfos[idx].orgId).then((res) => {
        item.wlzList = res.data.list;
        this.$forceUpdate();
      });
      getMarketingCenter(item.bscId).then((res) => {
        this.marketingCenterBsc = res.data.list;
      });
      for (let item of this.xsgmList) {
        item.wlz = "";
      }
      this.bscWlz = [];
    },
    // 选择所属运营商
    operatorInput(str, item) {
      if (!item.bscId) {
        this.$message.info("请选择所属办事处", 2);
        return;
      }
      if (!item.wlz) {
        this.$message.info("请选择物料组", 2);
        return;
      }
      let data = {
        searchstr: str,
        orgId: item.bscId,
        matklId: item.wlz,
      };
      getOperator(data).then((res) => {
        this.operator = res.data.list;
      });
    },
    operatorChange(id, item) {
      item.operatorId = id;
    },
    salesmanForus(e) {
    },
    salesmanInput(e, opt) {
      if (!opt.bscId) {
        this.$message.info("请选择所属办事处", 2);
        return;
      }
      let data = {
        searchstr: e,
        officeId: opt.bscId,
      };
      getSalesman(data).then((res) => {
        this.salesman = res.data.list;
      });
    },
    // 营销员改变
    salesmanChange(item, idx) {
      item.salesman = this.salesman[idx].code;
      item.salesmanPhone = this.salesman[idx].desc;
    },
    // 促销员输入
    promoterInput(e, opt) {
      if (!opt.bscId) {
        this.$message.info("请选择所属办事处", 2);
        return;
      }
      let data = {
        searchstr: e,
        officeId: opt.bscId,
      };
      getSalesman(data).then((res) => {
        this.promoter = res.data.list;
      });
    },
    // 促销员改变
    promoterChange(item, idx) {
      item.promoter = this.promoter[idx].code;
      item.promoterName = this.promoter[idx].desc;
    },
    // 获取下拉数据
    getParams() {
      return getParams().then((res) => {
        if (res.data.code == 0) {
          let data = res.data.dict;
          this.isCore = data.isCoreShop; //是否核心店铺
          this.isEnter = data.isEnter; //是否入住
          this.cooperationStatus = data.djFlag; //冻结
          this.isSink = data.isSinkChannel; //是否下沉
          this.isBooth = data.isSubmitSupplyId; //是否做展台
          this.isTop = data.isTopShop; //是否TOP客户加盟店
          this.marketLevels = data.marketLevelId; //市场级别
          this.storeCategory = data.shopCategory; //门店类别
          this.formData.storeCategory = [data.shopCategory[0].code];
          this.storefront = data.shopPropertyId; //店面性质
          this.brand = data.netStandardId; //通信品端信联
          this.isSap = data.isToSap; //是否发送SAP
          this.demandType = data.supplyTypeId; //需求类型
          this.dispatchType = data.isHeaderUnion; //派单类型
          this.cityLv = data.districtType; //城市级别
          this.category = data.branchId; //品牌
          this.storeLevel = data.shopLevelId;
          this.isStandard = data.isUpStandard;
          this.formData.isBooth = "14923253082";

          this.circleType = data.tradingTypeId; //商圈类型
          this.store3Size = data.threeScaleId; //门店三大品类年规模
          this.brandSettled = data.friendBrandId; //友商品牌进驻
          this.isMainFloor = data.isMainFloor; //是否主销楼层
          this.closedBrand = data.closedBrandId; // 紧临品牌
          this.isStandard = data.isUpStandard; //是否达标
        }
      });
    },
    // 供应商输入
    supplierInput(e, item) {
      if (item.dispatchType == 2) {
        let data = {
          searchstr: e,
        };
        getSupplier(data).then((res) => {
          this.supplier = res.data.list;
        });
      }
    },
    supplierChange(value, item) {
      let obj = this.supplier.find((i) => {
        //listData就是上面的数据源
        return i.code === value; //筛选出匹配数据
      });
      item.supplier = obj.code;
      item.supplierName = obj.name;
    },
    // 派单类型改变
    dispatchTypeChange(e, record) {
      this.selctRow = record;
      let obj = this.dispatchType.find((i) => {
        //listData就是上面的数据源
        return i.code == e; //筛选出匹配数据
      });
      record.dispatchType = obj.code;
      if (record.dispatchType == "1") {
        this.msgTips =
          "仅限于总部重点门店建店使用，不会自动派单，将由总部建店负责人在NP-SRM直接派工至总部指定搭建方，请再次确认！";
        this.visible2 = true;
        record.supplier = "";
        record.supplierName = "";
        this.$forceUpdate();
      } else if (record.dispatchType == "2") {
        this.msgTips =
          "仅针对连锁渠道指定商家（如：顶、地制作），请再次确认是否要进行连锁统装派单？如选择此项，后期将纳入审计重点核查门店！";
        this.visible2 = true;
        this.$forceUpdate();
      } else {
        record.supplier = "";
        record.supplierName = "";
        record.disabled = true;
        this.visible2 = false;
        this.$forceUpdate();
      }
    },
    // 点击确定弹窗删除
    handleOk() {
      if (this.selctRow.dispatchType == 2) {
        this.$forceUpdate();
      } else {
        this.selctRow.supplier = "";
        this.selctRow.supplierName = "";
        this.$forceUpdate();
      }
      this.visible2 = false;
    },
    // 点击取消 把当前的 需求类型置空
    handleCancel33() {
      this.visible2 = false;
      this.selctRow.dispatchType = "0";
      // this.selctRow.isHeaderUnionText = '普通安装派单'
      this.selctRow.supplier = "";
      this.selctRow.supplierName = "";
      this.$forceUpdate();
    },

    // getReason(content, id) {
    //     if (!id) {
    //         this.$message.info("请选择办事处经理", 2);
    //         return
    //     }
    //     if (!content) {
    //         this.$message.info("请输入申请原因", 2);
    //         return
    //     }
    //     this.bscManagerId = id;
    //     this.content = content;
    //     this.visible = false;
    //     this.spinning = true;

    //     let shopInfoDetailCheckList = [];
    //     for (let item of this.bscList) {
    //         shopInfoDetailCheckList.push({
    //             "isNewRow": true,
    //             "djFlag": "1",
    //             "custInfoId": this.formData.ownedBusiness,
    //             "orgId": item.companyId,
    //             "brandId": item.category,
    //             "baseMatklId": item.wlz,
    //             "shopLevelId": item.storeLevel,
    //             "shopArea": item.area,
    //             "modelCount": item.num,
    //             "managerName": item.manager,
    //             "managerTel": item.managerPhone,
    //             "sellerId": item.salesman,
    //             "isEnter": item.isEnter,
    //             "isTopShop": item.isTop,
    //             "runCustTypeId": item.operatorId,
    //             shopFloor: item.floor,
    //             netStandardId: item.brand,
    //             isToSap: item.isSap,
    //             terminalOrgId: item.marketingCenter,
    //             warehouseId: item.merchantId
    //         })
    //     }
    //     let custShopInfoSalesCheckList = []
    //     for (let item of this.xsgmList) {
    //         custShopInfoSalesCheckList.push({
    //             isNewRow: true,
    //             matklId: item.wlz,
    //             annualSales: item.nxsgm,
    //             forcastSales: item.yjmdgm
    //         })
    //     }
    //     let supplyDetailList = [];
    //     for (let item of this.storeDemands) {
    //         supplyDetailList.push({
    //             "baseMatklId": item.wlz,//物料组
    //             "enterTime": item.time,//要求完成时间
    //             "estimatedCost": item.costEstimate,//费用预估
    //             // "finishDate": "2022-03-30T16:00:00.000Z",
    //             "isHeaderUnion": item.dispatchType,//派单类型
    //             "isNewRow": true,
    //             "orgId": item.bsc,//办事处名称
    //             "remark": item.illustrate,//备注
    //             "supplierCode": item.supplier,//供应商
    //             "supplyTypeId": item.demandType,//需求类型
    //         })
    //     }

    // },
    // 门店全称输入
    storeFullNameSearch(e) {
      let data = {
        searchstr: e,
        type: 1,
      };
      getStore(data).then((res) => {
        if (res.data.code == 0) {
          this.storeFullNames = res.data.list;
        }
      });
    },
    storeShortNameSearch(e) {
      let data = {
        searchstr: e,
        type: 2,
      };
      getStore(data).then((res) => {
        if (res.data.code == 0) {
          this.storeShortNames = res.data.list;
        }
      });
    },
    // 查看是否达标
    checkStandard() {
      /**
                大客户 + !超市 = 大客户
                大客户 + 超市 = 超市
                自由渠道 + 普通商家 =普通商家
                异业新兴 + 工程渠道 =前置市场专卖店
            */
      let qdfl = ""; //渠道分类
      if (this.formData.labelLv1 == "大客户") {
        if (this.formData.labelLv2 != "超市") {
          qdfl = "大客户";
        }
        if (this.formData.labelLv2 == "超市") {
          qdfl = "超市";
        }
      }
      if (
        this.formData.labelLv1 == "自有渠道" &&
        this.formData.labelLv2 == "普通商家"
      ) {
        qdfl = "普通商家";
      }
      if (
        this.formData.labelLv1 == "异业新兴" &&
        this.formData.labelLv2 == "工程渠道"
      ) {
        qdfl = "前置市场专卖店";
      }
      if (!qdfl) {
        return true;
      }

      let circleType = ""; //商圈
      if (this.formData.circleType == "14925759199") {
        circleType = 1;
      }
      if (this.formData.circleType == "14925759200") {
        circleType = 2;
      }
      if (this.formData.circleType == "14925759201") {
        circleType = 3;
      }
      if (!circleType) {
        return;
      }
      let str = "";
      for (let item of this.bscList) {
        console.warn(item);
        let wlz = "";
        let storeLevel = item.storeLevel;
        // item.storeLevel
        /**
         * 14170992126 S-体验店（全品类）
         * 14170992127 A-旗舰店（各品类）
         * 14170992128 B-标准店（各品类）
         * 14170992129 C-基础店（各品类）
         */
        switch (storeLevel) {
          case "14170992126":
            storeLevel = "S";
            break;
          case "14170992127":
            storeLevel = "A";
            break;
          case "14170992128":
            storeLevel = "B";
            break;
          case "14170992129":
            storeLevel = "C";
            break;

          default:
            break;
        }

        if (item.wlzName.indexOf("电视") > -1) {
          wlz = "电视";
        }
        if (item.wlzName.indexOf("冰箱") > -1) {
          wlz = "冰箱";
        }
        if (item.wlzName.indexOf("洗衣机") > -1) {
          wlz = "洗衣机";
        }
        if (item.wlzName.indexOf("冷柜") > -1) {
          wlz = "冷柜";
        }
        if (item.wlzName.indexOf("空调") > -1) {
          wlz = "空调";
        }
        // 友商进驻
        if (
          this.formData.brandSettled != "14925759259" &&
          qdfl != "前置市场专卖店"
        ) {
          if (wlz == "电视" && this.formData.brandSettled != "14925759253") {
            // 电视索尼
            str += "友商品牌进驻要求电视-索尼,";
          }
          if (
            wlz == "冰箱" &&
            (this.formData.brandSettled != "14925759255" ||
              this.formData.brandSettled != "14925759257")
          ) {
            // 冰箱海尔或美的
            str += "友商品牌进驻要求冰箱-海尔或美的,";
          }
          if (
            wlz == "空调" &&
            (this.formData.brandSettled != "14925759258" ||
              this.formData.brandSettled != "14925759257")
          ) {
            // 空调格力或美的
            str += "友商品牌进驻要求冰箱-海尔或美的,";
          }
        }
        console.error(qdfl);
        if (qdfl == "大客户") {
          if (storeLevel == "S") {
            if (circleType == 3) {
              // 需要 一、二类商圈
              str += "商圈: 一、二类商圈,";
            }
            if (item.isMainFloor != 1 && item.floor < 3) {
              // 1-3层或主销层
              str += "楼层: 1-3层或主销层,";
            }
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }
            if (wlz == "电视" && item.peopleNum > 3) {
              // 人有配置 电视3
              str += "人员配置: 电视3,";
            }
            if ((wlz == "空调" || wlz == "冰箱") && item.area < 50) {
              // 海信冰箱、海信空调、容声冰箱 ≥50平米
              str += "面积: 海信冰箱、海信空调、容声冰箱 ≥50平米,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 2) {
              // 人有配置 冰冷2人
              str += "人员配置: 电视3,";
            }
            if (wlz == "洗衣机") {
              if (item.area < 10) {
                // 海信洗衣机≥10平米
                str += "面积: 海信洗衣机≥10平米,";
              }
              if (item.peopleNum > 1) {
                // 洗衣机1人
                str += "人员配置: 电视1,";
              }
            }

            if (this.formData.store3Size != "14925759230") {
              // 三大品类规模2亿以上
              str += "三大品类规模2亿以上,";
            }
          }
          if (storeLevel == "A") {
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }
            if (circleType == 3) {
              // 需要 一、二类商圈
              str += "商圈: 一、二类商圈,";
            }
            if (item.isMainFloor != 1 && item.floor < 3) {
              // 1-3层或主销层
              str += "楼层: 1-3层或主销层,";
            }

            if (this.formData.store3Size != "14925759231") {
              // 三大品类规模1.5-2亿
              str += "三大品类规模1.5-2亿,";
            }
            if (wlz == "电视" && item.peopleNum > 2) {
              // 人有配置 电视2
              str += "人员配置: 电视2,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 2) {
              // 人有配置 冰冷2人
              str += "人员配置: 冰冷2,";
            }
            if (wlz == "空调" && item.peopleNum > 2) {
              // 人有配置 空调不高于2人
              str += "人员配置: 空调不高于2,";
            }
            if (wlz == "洗衣机" && item.peopleNum > 1) {
              // 洗衣机1人
              str += "人员配置: 洗衣机1人,";
            }
          }
          if (storeLevel == "B") {
            // 需要 三类商圈以上
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (this.formData.store3Size != "14925759232") {
              // 三大品类规模1-1.5亿
              str += "三大品类规模1-1.5亿,";
            }
            if (wlz == "电视" && item.peopleNum > 2) {
              // 人有配置 电视2
              str += "人员配置: 电视2,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1,";
            }
            if (wlz == "空调" && item.peopleNum > 1) {
              // 人有配置 空调1人
              str += "人员配置: 空调1,";
            }
            if (wlz == "洗衣机" && item.peopleNum != 0) {
              // 洗衣机0
              str += "人员配置: 洗衣机不配,";
            }
          }

          if (wlz == "冰箱") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "冷柜") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "洗衣机") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "空调") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
        }
        if (qdfl == "超市") {
          if (storeLevel == "A") {
            if (circleType == 3) {
              // 需要 一、二类商圈
              str += "商圈: 一、二类商圈,";
            }
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }

            if (
              this.formData.store3Size != "14925759234" ||
              this.formData.store3Size != "14925759233" ||
              this.formData.store3Size != "14925759232" ||
              this.formData.store3Size != "14925759231" ||
              this.formData.store3Size != "14925759230"
            ) {
              // 三大品类规模3000万以上
              str += "三大品类规模3000万以上,";
            }

            if (wlz == "电视" && item.peopleNum > 1) {
              // 人有配置 电视1
              str += "人员配置: 电视1,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1人,";
            }
            if (wlz == "空调" && item.peopleNum != 0) {
              // 人有配置 空调0人
              str += "人员配置: 空调不配备,";
            }
            if (wlz == "洗衣机" && item.peopleNum != 0) {
              // 洗衣机0
              str += "人员配置: 洗衣机不配备,";
            }
          }
          if (storeLevel == "B") {
            // 需要 三类商圈以上
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }

            if (this.formData.store3Size != "14925759235") {
              // 三大品类规模2000万-3000万
              str += "三大品类规模2000万-3000万,";
            }
            if (item.peopleNum != 0) {
              // 人有配置0
              str += "人员配置: 不配备,";
            }
          }
          if (wlz == "电视") {
          }
          if (wlz == "冰箱") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "冷柜") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "洗衣机") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "空调") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
        }
        if (qdfl == "普通商家") {
          if (storeLevel == "S") {
            // 需要 三类商圈以上
            if (item.isMainFloor != 1 && item.floor != 1) {
              // 1层或主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 1) {
              // 第1位置
              str += "位置: 第1位置,";
            }

            if (
              this.formData.store3Size != "14925759233" &&
              this.formData.store3Size != "14925759232" &&
              this.formData.store3Size != "14925759231" &&
              this.formData.store3Size != "14925759230"
            ) {
              // 三大品类规模5000万以上
              str += "三大品类规模5000万以上,";
            }

            if (wlz == "电视" && item.peopleNum > 2) {
              // 人有配置 电视2
              str += "人员配置: 电视2,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1人,";
            }
            if (wlz == "空调" && item.peopleNum > 1) {
              // 人有配置 空调不高于1人
              str += "人员配置: 空调不高于1人,";
            }
            if (wlz == "洗衣机" && item.peopleNum != 0) {
              // 洗衣机0人
              str += "人员配置: 洗衣机不配备,";
            }
          }
          if (storeLevel == "A") {
            // 需要 三类商圈以上
            if (item.isMainFloor != 1 && item.floor < 3) {
              // 1-3层或主销层
              str += "楼层: 1-3层或主销层,";
            }
            if (item.locationRanking > 1) {
              // 第1位置
              str += "位置: 第1位置,";
            }
            if (this.formData.store3Size != "14925759234") {
              // 三大品类规模3000万-5000万
              str += "三大品类规模3000万-5000万,";
            }
            if (wlz == "电视" && item.peopleNum > 1) {
              // 人有配置 电视2
              str += "人员配置: 电视2,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1人,";
            }
            if (wlz == "空调" && item.peopleNum > 1) {
              // 人有配置 空调不高于1人
              str += "人员配置: 空调不高于1人,";
            }
            if (wlz == "洗衣机" && item.peopleNum != 0) {
              // 洗衣机0人
              str += "人员配置: 洗衣机不配备,";
            }
          }
          if (storeLevel == "B") {
            // 需要 三类商圈以上
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 1) {
              // 第1位置
              str += "位置: 第1位置,";
            }
            if (
              this.formData.store3Size != "14925759235" &&
              this.formData.store3Size != "14925759236"
            ) {
              // 三大品类规模1000万-3000万
              str += "三大品类规模1000万-3000万,";
            }
            if (wlz == "电视" && item.peopleNum > 1) {
              // 人有配置 电视2
              str += "人员配置: 电视2,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1人,";
            }
            if (wlz == "空调" && item.peopleNum > 1) {
              // 人有配置 空调不高于1人
              str += "人员配置: 空调不高于1人,";
            }
            if (wlz == "洗衣机" && item.peopleNum != 0) {
              // 洗衣机0人
              str += "人员配置: 洗衣机不配备,";
            }
          }
          if (wlz == "电视") {
          }
          if (wlz == "冰箱") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "冷柜") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "洗衣机") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
          if (wlz == "空调") {
            if (
              item.closedBrand != "14925759217" ||
              item.closedBrand != "14925759218"
            ) {
              // 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的
              str += "紧邻品牌: 冰洗冷紧邻海尔、美的 空调紧邻海尔、美的,";
            }
          }
        }
        if (qdfl == "前置市场专卖店") {
          if (storeLevel == "S") {
            if (circleType == 3) {
              // 需要 一、二类商圈
              str += "商圈: 一、二类商圈,";
            }
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }

            if (
              this.formData.store3Size != "14925759235" &&
              this.formData.store3Size != "14925759234" &&
              this.formData.store3Size != "14925759233" &&
              this.formData.store3Size != "14925759232" &&
              this.formData.store3Size != "14925759231" &&
              this.formData.store3Size != "14925759230"
            ) {
              // 三大品类规模2000万以上
              console.error(this.formData.store3Size);
              str += "三大品类规模2000万以上,";
            }
            if (wlz == "电视" && item.peopleNum > 1) {
              // 人有配置 电视1
              str += "人员配置: 电视1,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1人,";
            }
            if (wlz == "空调" && item.peopleNum > 1) {
              // 人有配置 空调不高于1人
              str += "人员配置: 空调不高于1人,";
            }
          }
          if (storeLevel == "A") {
            if (circleType == 3) {
              // 需要 一、二类商圈
              str += "商圈: 一、二类商圈,";
            }
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }
            if (
              this.formData.store3Size != "14925759235" &&
              this.formData.store3Size != "14925759234" &&
              this.formData.store3Size != "14925759233" &&
              this.formData.store3Size != "14925759232" &&
              this.formData.store3Size != "14925759231" &&
              this.formData.store3Size != "14925759230"
            ) {
              // 三大品类规模1500万以上
              str += "三大品类规模1500万以上,";
            }
            if (wlz == "电视" && item.peopleNum > 1) {
              // 人有配置 电视1
              str += "人员配置: 电视1,";
            }
            if ((wlz == "冷柜" || wlz == "冰箱") && item.peopleNum > 1) {
              // 人有配置 冰冷1人
              str += "人员配置: 冰冷1人,";
            }
            if (wlz == "空调" && item.peopleNum > 1) {
              // 人有配置 空调不高于1人
              str += "人员配置: 空调不高于1人,";
            }
          }
          if (storeLevel == "B") {
            if (circleType == 3) {
              // 需要 一、二类商圈
              str += "商圈: 一、二类商圈,";
            }
            if (item.isMainFloor != 1) {
              // 主销层
              str += "楼层: 主销层,";
            }
            if (item.locationRanking > 3) {
              // 前3位置
              str += "位置: 前3位置,";
            }
            if (item.store3Size == "14925759237") {
              // 三大品类规模1000万以上
              str += "三大品类规模1000万以上,";
            }
            if (item.peopleNum > 1) {
              // 人有配置 不高于1人
              str += "人员配置: 整体配置一人1人,";
            }
          }
          // if(item.closedBrand != '14925759217' || item.closedBrand != '博西') {
          //     // 紧邻海尔、博西
          // }
        }
      }
      for (let item of this.xsgmList) {
        let wlz = "";
        if (item.wlzName.indexOf("电视") > -1) {
          wlz = "电视";
        }
        if (item.wlzName.indexOf("冰箱") > -1) {
          wlz = "冰箱";
        }
        if (item.wlzName.indexOf("洗衣机") > -1) {
          wlz = "洗衣机";
        }
        if (item.wlzName.indexOf("冷柜") > -1) {
          wlz = "冷柜";
        }
        if (item.wlzName.indexOf("空调") > -1) {
          wlz = "空调";
        }
        let itwlz = "";
        for (let it of this.bscList) {
          if (it.wlzName.indexOf("电视") > -1) {
            itwlz = "电视";
          }
          if (it.wlzName.indexOf("冰箱") > -1) {
            itwlz = "冰箱";
          }
          if (it.wlzName.indexOf("洗衣机") > -1) {
            itwlz = "洗衣机";
          }
          if (it.wlzName.indexOf("冷柜") > -1) {
            itwlz = "冷柜";
          }
          if (it.wlzName.indexOf("空调") > -1) {
            itwlz = "空调";
          }
          if (wlz == itwlz) {
            item.storeLevel = it.storeLevel;
          }
        }
        let storeLevel = item.storeLevel;
        /**
         * 14170992126 S-体验店（全品类）
         * 14170992127 A-旗舰店（各品类）
         * 14170992128 B-标准店（各品类）
         * 14170992129 C-基础店（各品类）
         */
        switch (storeLevel) {
          case "14170992126":
            storeLevel = "S";
            break;
          case "14170992127":
            storeLevel = "A";
            break;
          case "14170992128":
            storeLevel = "B";
            break;
          case "14170992129":
            storeLevel = "C";
            break;

          default:
            break;
        }

        if (qdfl == "大客户") {
          if (storeLevel == "S") {
            if (wlz == "电视") {
              if (item.yjmdgm < 800) {
                str += "电视门店规模800万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 250) {
                str += "海信冰冷250万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 300) {
                str += "空调300万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 80) {
                str += "洗衣机80万以上,";
              }
            }
          }
          if (storeLevel == "A") {
            console.error(item.yjmdgm);
            if (wlz == "电视") {
              if (item.yjmdgm < 300) {
                str += "电视门店规模300万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 120) {
                str += "海信冰冷120万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 100) {
                str += "空调100万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 40) {
                str += "洗衣机40万以上,";
              }
            }
          }
          if (storeLevel == "B") {
            if (wlz == "电视") {
              if (item.yjmdgm < 80) {
                str += "电视门店规模80万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 60) {
                str += "海信冰冷60万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 80) {
                str += "空调80万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 20) {
                str += "洗衣机20万以上,";
              }
            }
          }
        }
        if (qdfl == "超市") {
          if (storeLevel == "A") {
            if (wlz == "电视") {
              if (item.yjmdgm < 60) {
                str += "电视门店规模60万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 30) {
                str += "海信冰冷30万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 30) {
                str += "空调30万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 15) {
                str += "洗衣机15万以上,";
              }
            }
          }
          if (storeLevel == "B") {
            if (wlz == "电视") {
              if (item.yjmdgm < 40) {
                str += "电视门店规模40万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 20) {
                str += "海信冰冷20万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 20) {
                str += "空调20万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 10) {
                str += "洗衣机10万以上,";
              }
            }
          }
        }
        if (qdfl == "普通商家") {
          if (storeLevel == "S") {
            if (wlz == "电视") {
              if (item.yjmdgm < 200) {
                str += "电视门店规模200万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 80) {
                str += "海信冰冷80万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 70) {
                str += "空调70万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 30) {
                str += "洗衣机30万以上,";
              }
            }
          }
          if (storeLevel == "A") {
            if (wlz == "电视") {
              if (item.yjmdgm < 120) {
                str += "电视门店规模120万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 60) {
                str += "海信冰冷60万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 50) {
                str += "空调50万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 20) {
                str += "洗衣机20万以上,";
              }
            }
          }
          if (storeLevel == "B") {
            if (wlz == "电视") {
              if (item.yjmdgm < 80) {
                str += "电视门店规模80万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 40) {
                str += "海信冰冷40万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 40) {
                str += "空调40万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 10) {
                str += "洗衣机10万以上,";
              }
            }
          }
        }
        if (qdfl == "前置市场专卖店") {
          if (storeLevel == "S") {
            if (wlz == "电视") {
              if (item.yjmdgm < 200) {
                str += "电视门店规模200万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 100) {
                str += "海信冰冷100万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 150) {
                str += "空调150万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 50) {
                str += "洗衣机50万以上,";
              }
            }
          }
          if (storeLevel == "A") {
            if (wlz == "电视") {
              if (item.yjmdgm < 150) {
                str += "电视门店规模150万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 80) {
                str += "海信冰冷80万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 70) {
                str += "空调70万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 30) {
                str += "洗衣机30万以上,";
              }
            }
          }
          if (storeLevel == "B") {
            if (wlz == "电视") {
              if (item.yjmdgm < 80) {
                str += "电视门店规模80万以上,";
              }
            }
            if (wlz == "冰箱" || wlz == "冷柜") {
              if (item.yjmdgm < 40) {
                str += "海信冰冷40万以上,";
              }
            }
            if (wlz == "空调") {
              if (item.yjmdgm < 50) {
                str += "空调50万以上,";
              }
            }
            if (wlz == "洗衣机") {
              if (item.yjmdgm < 30) {
                str += "洗衣机30万以上,";
              }
            }
          }
        }
      }
      if (!str) {
        this.formData.isStandard = "是";
      } else {
        this.formData.isStandard = "否";
      }
      console.error(str);
      this.formData.standardDescribe = str;
    },
    save() {
      // debugger
      if (this.submitLine ? !this.submitLine.cooldown : true) {
        this.submitLine = this.setDistributorWarehouse();
        this.submitLine.cooldown = true;
        // debugger
      }

      return;
      this.checkStandard();
      if (
        this.formData.isBooth == 14923253081 &&
        !this.formData.projectRequirements
      ) {
        this.$message.info("请输入项目需求名称", 2);
        return;
      }
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.spinning = true;

          let shopInfoDetailCheckList = [];
          for (let item of this.bscList) {
            shopInfoDetailCheckList.push({
              isNewRow: this.type == "add" ? true : item.isNewRow,
              id: item.id || "",
              djFlag: "1",
              custInfoId: this.formData.ownedBusiness,
              orgId: item.companyId,
              brandId: item.category,
              baseMatklId: item.wlz,
              shopLevelId: item.storeLevel,
              shopArea: item.area,
              singleMeter: item.ztym, //展台延米
              modelCount: item.num,
              managerName: item.manager,
              managerTel: item.managerPhone,
              sellerId: item.salesman,
              isEnter: item.isEnter,
              isTopShop: item.isTop,
              runCustTypeId: item.operatorId,
              shopFloor: item.floor,
              netStandardId: item.brand,
              isToSap: item.isSap,
              terminalOrgId: item.marketingCenter,
              warehouseId: item.merchantId,
              djVouchers: item.file,

              isMainFloor: item.isMainFloor, //是否主销楼层
              placeRank: item.locationRanking, //位置排名
              closedBrandId: item.closedBrand, //紧邻品牌
              cxPeopleCount: item.peopleNum, //计划配置促销员人数
              cxPeopleId: item.promoter, //促销员
              cxPeopleName: item.promoterName, //促销员名字
            });
          }
          let custShopInfoSalesCheckList = [];
          for (let item of this.xsgmList) {
            custShopInfoSalesCheckList.push({
              isNewRow: this.type == "add" ? true : item.isNewRow,
              id: item.id || "",
              matklId: item.wlz,
              annualSales: item.nxsgm,
              forcastSales: item.yjmdgm,
            });
          }
          let supplyDetailList = [];
          for (let item of this.storeDemands) {
            if (!item.startYear || !item.endYear) {
              this.$message.info("请选择展台使用年限", 2);
              return;
            }
            supplyDetailList.push({
              id: item.id || "",
              baseMatklId: item.wlz, //物料组
              enterTime: item.time, //要求完成时间
              estimatedCost: item.costEstimate, //费用预估
              // "finishDate": "2022-03-30T16:00:00.000Z",
              isHeaderUnion: item.dispatchType, //派单类型
              isNewRow: this.type == "add" ? true : item.isNewRow,
              orgId: item.bsc, //办事处名称
              remark: item.illustrate, //备注
              supplierCode: item.supplier, //供应商
              supplierName: item.supplierName,
              supplyTypeId: item.demandType, //需求类型

              boothServiceLife: item.startYear + "-" + item.endYear, //展台使用年限
              annualSalesScale: item.netSize, //年销售规模（信天翁使用）
            });
          }
          let data = {
            id: this.type == "add" ? 0 : this.$route.query.id, //审批id,0表示没有
            attachs: [], //附件id
            deleteDetailList: [],
            custInfoId: this.formData.ownedBusiness, //商家id
            fullName: this.formData.storeFullName, //门店名称
            searchTerm: this.formData.storeShortName, //门店简称
            marketLevelId: this.formData.marketLevel, //市场级别
            provinceId: this.formData.province, //省
            cityId: this.formData.city, //市
            countyId: this.formData.area, //县/区
            townId: this.formData.street, //镇
            // "shAddress": "山东省青岛市市南区湛山街道镇江南路海信学校2",//地址
            shAddress: this.formData.address, //地址
            addressDesc: this.formData.addrDescribe, //地址描述
            longitude: this.gdCenter.lng,
            latitude: this.gdCenter.lat,
            postalCode: this.formData.postCode, //邮编
            districtType: this.formData.cityLv, //城市级别
            marketCenterId: this.formData.marketingCenterId, //营销中心
            shopPropertyId: this.formData.storefront, //店面性质
            isSinkChannel: this.formData.isSink, //是否下沉门店1是，0否
            shopCategory: this.formData.storeCategory, //门店类别
            primaryChannelId: this.formData.channelCategoryId, //商家-渠道
            secondChannelId: this.formData.channelCategorySubId, //商家-渠道明细
            primaryLabelId: this.formData.labelLv1Id, // 集团一级标签ID
            secondLabelId: this.formData.labelLv2Id, //集团二级渠道ID
            source: "XTW", //来源
            isSubmitSupplyId: this.formData.isBooth,
            projectSupplyName: this.formData.projectRequirements,
            projectPlanId:
              this.formData.isBooth != 14923253081 ? "" : 14182428490,
            isCoreShop: this.formData.isCore,
            areaLeaderId: this.formData.principal,
            totalAnnual: this.formData.znxsgm,
            totalForcast: this.formData.zyjmdgm,
            bscManagerId: this.bscManagerId,
            content: this.formData.content,
            shopInfoDetailCheckList: shopInfoDetailCheckList,
            custShopInfoSalesCheckList: custShopInfoSalesCheckList,
            supplyDetailList,

            friendBrandId: [this.formData.brandSettled], //友商品牌进驻Id
            threeScaleId: this.formData.store3Size, //门店三大品类年规模
            tradingTypeId: this.formData.circleType, //商圈类型
            isUpStandard: this.formData.isStandard, //门店建店是否达标
            noStandardReason: this.formData.standardDescribe, //未达标项描述
            isPlanSpecial: this.formData.isTobeHxStore, //是否计划成为海信专卖店
          };
          save(data).then((res) => {
            this.spinning = false;
            if (res.data.code == 0) {
              this.$message.success("提交成功", 2);
              setTimeout(() => {
                this.$router.push("/");
              }, 2000);
            } else {
              this.$message.info(res.data.msg, 2);
            }
          });
        } else {
          this.$message.info("请将信息填写完整", 2);
        }
      });
    },
    // 是否做展台变更
    isBoothChange(e) {
      if (e != 14923253081) {
        this.formData.projectRequirements = "";
        this.rules = {
          ownedBusiness: [
            {
              required: true,
              message: "请选择所属商家",
              trigger: ["change", "blur"],
            },
          ],
          marketLevel: [
            {
              required: true,
              message: "请选择市场级别",
              trigger: ["change", "blur"],
            },
          ],
          storeFullName: [
            {
              required: true,
              message: "请输入仓库全称",
              trigger: ["change", "blur"],
            },
          ],
          storeShortName: [
            {
              required: true,
              message: "请输入仓库简称",
              trigger: ["change", "blur"],
            },
          ],
          province: [
            {
              required: true,
              message: "请选择省",
              trigger: ["change", "blur"],
            },
          ],
          city: [
            {
              required: true,
              message: "请选择市",
              trigger: ["change", "blur"],
            },
          ],
          area: [
            {
              required: true,
              message: "请选择区县",
              trigger: ["change", "blur"],
            },
          ],
          street: [
            {
              required: true,
              message: "请选择乡镇街道",
              trigger: ["change", "blur"],
            },
          ],
          // marketLv: [{
          //     required: true,
          //     message: "请选择市场级别",
          //     trigger: ["change", "blur"],
          // }],
          address: [
            {
              required: true,
              message: "请选择门店地址",
              trigger: ["change", "blur"],
            },
          ],
          postCode: [
            {
              required: true,
              message: "请输入邮政编码",
              trigger: ["change", "blur"],
            },
            {
              validator: this.isPostCode,
              message: "请输入正确的邮政编码",
              trigger: ["change", "blur"],
            },
          ],
          cityLv: [
            {
              required: true,
              message: "请选择城市级别",
              trigger: ["change", "blur"],
            },
          ],
          storefront: [
            {
              required: true,
              message: "请选择店面性质",
              trigger: ["change", "blur"],
            },
          ],
          isSink: [
            {
              required: true,
              message: "请选择是否下沉渠道",
              trigger: ["change", "blur"],
            },
          ],
          storeCategory: [
            {
              required: true,
              message: "请选择门店类别",
              trigger: ["change", "blur"],
            },
          ],
          isCore: [
            {
              required: true,
              message: "请选择是否核心门店",
              trigger: ["change", "blur"],
            },
          ],
          principal: [
            {
              required: true,
              message: "请选择片区负责人",
              trigger: ["change", "blur"],
            },
          ],
          isBooth: [
            {
              required: true,
              message: "请选择是否做展台",
              trigger: ["change", "blur"],
            },
          ],
          content: [
            {
              required: true,
              message: "请输入申请原因",
              trigger: ["change", "blur"],
            },
          ],
          projectRequirements: [
            {
              required: false,
              message: "请输入项目需求名称",
              trigger: ["change", "blur"],
            },
          ],
        };
      } else {
        this.rules = {
          ownedBusiness: [
            {
              required: true,
              message: "请选择所属商家",
              trigger: ["change", "blur"],
            },
          ],
          marketLevel: [
            {
              required: true,
              message: "请选择市场级别",
              trigger: ["change", "blur"],
            },
          ],
          storeFullName: [
            {
              required: true,
              message: "请输入仓库全称",
              trigger: ["change", "blur"],
            },
          ],
          storeShortName: [
            {
              required: true,
              message: "请输入仓库简称",
              trigger: ["change", "blur"],
            },
          ],
          province: [
            {
              required: true,
              message: "请选择省",
              trigger: ["change", "blur"],
            },
          ],
          city: [
            {
              required: true,
              message: "请选择市",
              trigger: ["change", "blur"],
            },
          ],
          area: [
            {
              required: true,
              message: "请选择区县",
              trigger: ["change", "blur"],
            },
          ],
          street: [
            {
              required: true,
              message: "请选择乡镇街道",
              trigger: ["change", "blur"],
            },
          ],
          // marketLv: [{
          //     required: true,
          //     message: "请选择市场级别",
          //     trigger: ["change", "blur"],
          // }],
          address: [
            {
              required: true,
              message: "请选择门店地址",
              trigger: ["change", "blur"],
            },
          ],
          postCode: [
            {
              required: true,
              message: "请输入邮政编码",
              trigger: ["change", "blur"],
            },
            {
              validator: this.isPostCode,
              message: "请输入正确的邮政编码",
              trigger: ["change", "blur"],
            },
          ],
          cityLv: [
            {
              required: true,
              message: "请选择城市级别",
              trigger: ["change", "blur"],
            },
          ],
          storefront: [
            {
              required: true,
              message: "请选择店面性质",
              trigger: ["change", "blur"],
            },
          ],
          isSink: [
            {
              required: true,
              message: "请选择是否下沉渠道",
              trigger: ["change", "blur"],
            },
          ],
          storeCategory: [
            {
              required: true,
              message: "请选择门店类别",
              trigger: ["change", "blur"],
            },
          ],
          isCore: [
            {
              required: true,
              message: "请选择是否核心门店",
              trigger: ["change", "blur"],
            },
          ],
          principal: [
            {
              required: true,
              message: "请选择片区负责人",
              trigger: ["change", "blur"],
            },
          ],
          isBooth: [
            {
              required: true,
              message: "请选择是否做展台",
              trigger: ["change", "blur"],
            },
          ],
          content: [
            {
              required: true,
              message: "请输入申请原因",
              trigger: ["change", "blur"],
            },
          ],
          projectRequirements: [
            {
              required: true,
              message: "请输入项目需求名称",
              trigger: ["change", "blur"],
            },
          ],
        };
      }
    },
    // 办事处物料组变更
    wlzChange(code, item) {
      for (let it of item.wlzList) {
        if (code == it.code) {
          item.wlzName = it.name;
          item.wlz = it.code;
          break;
        }
      }
      let bscWlz = [];
      for (let item of this.bscList) {
        bscWlz.push({
          bsc: item.bscId,
          bscName: item.bscName,
          code: item.wlz,
          name: item.wlzName,
        });
      }
      this.bscWlz = bscWlz;
    },
    xsgmWlzChange(code, item) {
      for (let it of this.bscWlz) {
        if (code == it.code) {
          item.wlzName = it.name;
          break;
        }
      }
    },
    upload(info, idx) {
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          this.bscList[idx].file = [
            {
              name: info.file.name,
              id: info.file.response.businessId,
            },
          ];
        } else {
          this.$message.warning("上传失败");
        }
      }
    },
    delImg(idx) {
      this.bscList[idx].file = [];
    },
    timeChange(e, defaultTime, record) {
      record.time = defaultTime;
    },
    handleChangeStart(value, record) {
     
      if (record.endYear && record.startYear > record.endYear) {
        this.$message.warning("输入时间不能大于结束时间,请重新选择");
        record.startYear = "";
        return;
      }
    },
    handleChangeEnd(value, record) {
      
      if (record.startYear && record.endYear < record.startYear) {
        this.$message.warning("输入时间小于开始时间,请重新选择");
        record.endYear = "";
        return;
      }
    },
  },
};
